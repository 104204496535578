import type { PaginateResult } from "mongoose"
import type { DomainType, Organization } from "./organization.store"
import type { MovementType } from "./types"

export interface TemplateComponent {
  _id: string
  component: string
  description: string
  sortOrder: number
  optionalProperties: Array<string>
}

export interface TemplateComponentGroup {
  _id: string
  componentGroup: string
  description: string
  sortOrder: number
  components: Array<TemplateComponent>
}

export interface PopulatedComponentGroup extends Omit<ComponentGroup, "components"> {
  components: Array<PopulatedComponent>
}

export interface PopulatedComponent extends Omit<Component, "componentType" | "optionalProperties"> {
  componentType: ComponentType["description"]
  optionalProperties: Array<string>
}

export interface PopulatedTemplateComponentGroup extends Omit<TemplateComponentGroup, "componentGroup" | "components"> {
  componentGroup: PopulatedComponentGroup
  components: Array<PopulatedTemplateComponent>
}

export interface PopulatedTemplateComponent extends Omit<TemplateComponent, "component" | "optionalProperties"> {
  component: Component
  componentType: ComponentType["description"]
  optionalProperties?: Array<string>
}

export interface Template {
  _id: string
  organization?: string
  description: string
  componentGroups: Array<TemplateComponentGroup>
  domain: DomainType
}

export interface PopulatedTemplate extends Omit<Template, "organization" | "componentGroups"> {
  organization?: Organization
  componentGroups: Array<PopulatedTemplateComponentGroup>
  domain: DomainType
}

export interface TemplateUsedByResponseRecord {
  /** Id of the organization */
  organizationId: string
  /** Description of the organization */
  description: string
  /** Ids of the assets */
  assets: Array<string>
}

export interface TemplateUsedByResponse {
  templateId: string
  organizations: Array<TemplateUsedByResponseRecord>
  totalAssets: number
}

export interface SmallPopulatedTemplate {
  _id: string,
  description: string,
  organization?: null | Pick<Organization, "_id" | "description">,
}

export const useTemplateStore = defineStore("template", () => {
  const getTemplates = async () =>
    useCrudMethods<SmallPopulatedTemplate>("/templates").readItems({
      pagination: false,
      select: JSON.stringify({
        description: 1,
        organization: 1,
      }),
      populate: JSON.stringify([{
        path: "organization",
        model: "Organization",
        select: "description",
      }]),
      sort: JSON.stringify({
        description: 1
      })
    })

  const getAllTemplates = async (queryParameters: Record<string, unknown> = {}): Promise<PaginateResult<Template>> => {
    const query = getUrlSearchParams(queryParameters)
    const { data } = await useSamApi<PaginateResult<Template>>(`/templates?${query}`)
    return data
  }

  const getOneById = async (id: string): Promise<PopulatedTemplate> => {
    const { data } = await useSamApi<PopulatedTemplate>(`/templates/${id}`)
    return data
  }

  const addTemplate = async (description: string, domain: DomainType, organization?: string, copyFromTemplate?: string): Promise<Template> => {
    const { data } = await useSamApi<Template>("/templates", {
      method: "POST",
      body: JSON.stringify({
        description,
        domain,
        organization,
        copyFromTemplate,
      })
    })
    return data
  }

  const updateTemplate = async (id: string, domain: DomainType, description?: string, organization?: string): Promise<Template> => {
    const { data } = await useSamApi<Template>(`/templates/${id}`, {
      method: "PUT",
      body: JSON.stringify({ domain: domain, description: description, organization: organization ?? ""})
    })
    return data
  }

  const deleteTemplate = async (id: string): Promise<Template> => {
    const { data } = await useSamApi<Template>(`/templates/${id}`, {
      method: "DELETE"
    })
    return data
  }

  const addComponentGroup = async (templateId: string, componentGroupId: string, description?: string): Promise<Template> => {
    const { data } = await useSamApi<Template>(`/templates/${templateId}/component-group`, {
      method: "POST",
      body: JSON.stringify({ componentGroupId: componentGroupId, description: description })
    })
    return data
  }

  const updateComponentGroup = async (templateId: string, componentGroupId: string, description: string): Promise<Template> => {
    const { data } = await useSamApi<Template>(`/templates/${templateId}/component-group/${componentGroupId}`, {
      method: "PUT",
      body: JSON.stringify({ componentGroupId: componentGroupId, description: description })
    })
    return data
  }

  const removeComponentGroup = async (templateId: string, componentGroupId: string): Promise<Template> => {
    const { data } = await useSamApi<Template>(`/templates/${templateId}/component-group/${componentGroupId}`, {
      method: "DELETE"
    })
    return data
  }

  const updateComponentGroupSort = async (templateId: string, componentGroupId: string, movementType: MovementType): Promise<Template> => {
    const { data } = await useSamApi<Template>(`/templates/${templateId}/component-group/${componentGroupId}/sort`, {
      method: "PUT",
      body: JSON.stringify({ movement: movementType })
    })
    return data
  }

  const updateComponentSort = async (templateId: string, componentGroupId: string, componentId: string, movementType: MovementType): Promise<Template> => {
    const { data } = await useSamApi<Template>(`/templates/${templateId}/component-group/${componentGroupId}/component/${componentId}/sort`, {
      method: "PUT",
      body: JSON.stringify({ movement: movementType })
    })
    return data
  }

  const addComponent = async (templateId: string, componentGroupId: string, componentId: string, description?: string): Promise<Template> => {
    const { data } = await useSamApi<Template>(`/templates/${templateId}/component-group/${componentGroupId}/component`, {
      method: "POST",
      body: JSON.stringify({ componentId: componentId, description: description})
    })
    return data
  }

  const updateComponent = async (templateId: string, componentGroupId: string, componentId: string, description?: string, optionalProperties?: Array<string>): Promise<Template> => {
    const { data } = await useSamApi<Template>(`/templates/${templateId}/component-group/${componentGroupId}/component/${componentId}`, {
      method: "PUT",
      body: JSON.stringify({ componentId: componentId, description: description, optionalProperties: optionalProperties })
    })
    return data
  }

  const removeComponent = async (templateId: string, componentGroupId: string, componentId: string): Promise<Template> => {
    const { data } = await useSamApi<Template>(`/templates/${templateId}/component-group/${componentGroupId}/component/${componentId}`, {
      method: "DELETE"
    })
    return data
  }

  const getTemplateUsedBy = async (templateId: string): Promise<TemplateUsedByResponse> => {
    const { data } = await useSamApi<TemplateUsedByResponse>(`/templates/${templateId}/used-by`)
    return data
  }

  return {
    getTemplates,
    getAllTemplates,
    getOneById,
    addTemplate,
    updateTemplate,
    deleteTemplate,

    addComponentGroup,
    updateComponentGroup,
    removeComponentGroup,

    updateComponentGroupSort,
    updateComponentSort,

    addComponent,
    updateComponent,
    removeComponent,

    getTemplateUsedBy,
  }
})